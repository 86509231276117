import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    language: 'english',
    text: {
        landing: {
            startText: 'AFS: Secure, Fast,  and Reliable Crypto Trading',
            getStarted: 'Get Started',
            followTitle: 'Follow our news and offers',
            view: 'VIEW',
            tradingTitle: 'World class trading platform',
            tradingSubTitle: 'We rank top among first tier exchanges with highest number of listed crypto',
            customersTitle: 'Why customers choose us?',
            customersSubTitle: 'Get all the benefits of YukiTale’s crypto exchange  in the palm of your hand. Here are a few reasons why you should choose us',
            box1title: 'Secure and Trusted',
            box2title: 'Fast and Efficient',
            box3title: 'Powerful Trading Tools',
            box4title: 'Wide Range of Crypto',
            box1text: 'Your security is our top priority. We employ industry-leading security measures to safeguard your funds and personal information. You can trade with confidence knowing that your assets are protected',
            box2text: 'Our advanced trading engine ensures lightning-fast order execution, enabling you to take advantage of market opportunities as they arise. No more waiting for sluggish transactions – experience the speed and efficiency of our platform',
            box3text: 'From real-time price charts and technical analysis indicators to advanced order types and customizable trading strategies, we provide the tools you need to make informed trading decisions',
            box4text: 'Discover a diverse selection of cryptocurrencies on our platform. Whether you\'re interested in Bitcoin, Ethereum, or other popular altcoins, we\'ve got you covered. Expand your portfolio and explore new investment opportunities',
            exchangeTop: 'App',
            exchangeTitle: 'Trade anywhere and anytime with AFS',
            exchangeDescription: 'Enjoy the convenience of AFS crypto trading platform. Make money on cryptocurrency anytime, anywhere as AFS',
            exchangeBtnComputer: 'Download computer',
            exchangeBtnMobile: 'Download mobile',
            newsletterTitle: 'Subscribe to our newsletter',
            newsletterText: 'We can’t promise a new email every Monday, but we can promise we won’t spam you until we have  a great announcement to share',
            newsletterEmail: 'Enter your email',
            newsletterSendBtn: 'Subscribe',
            lockText: 'Unlock everything Trove Pro has to offer',
            signIn: 'Sign in',
            signUp: 'Sign up',
        },
        signIn: {
            title: 'Sign in',
            subTitle: 'Welcome back! Please enter your details',
            email: 'Email',
            emailPlaceholder: 'Enter your email',
            password: 'Password',
            passwordPlaceholder: 'Enter your password',
            notHaveAccount: 'Don’t have an account?',
            signUpLink: 'Sign up for free',
            captchaError: 'Please confirm that you are not a robot',
        },
        signUp: {
            title: 'Create new account',
            subTitle: 'Welcome! Please enter your details',
            login: 'Login',
            loginPlaceholder: 'Enter your login',
            email: 'Email',
            emailPlaceholder: 'Enter your email',
            password: 'Password',
            passwordPlaceholder: 'Enter your password',
            passwordRepeat: 'Repeat the password',
            signUp: 'Sign up',
            captchaError: 'Please confirm that you are not a robot',
        },
        header: {
            login: 'Log in',
            signUp: 'Sign up',
            trading: 'Trading',
            p2p: 'P2P',
            staking: 'Staking',
            wallet: 'Wallet',
            swap: 'Swap',
            lastActivity: 'Last activity time:',
            totalBalance: 'Total balance',
            spotTrading: 'Spot trading',
            earn: 'Earn',
            support: 'Support',
            buyCrypto: 'Buy crypto',
            tools: 'Tools',
        },
        menu: {
            adminPanel: 'Admin Panel',
            wallet: 'Wallet',
            notifications: 'Notifications',
            accountSettings: 'Account settings',
            verification: 'Verification',
            giftCodes: 'Gift codes',
            support: 'Support',
            lightTheme: 'Light theme',
            exit: 'Exit',
        },
        headerNavigation: {
            briefcase: 'Briefcase',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            transfer: 'Transfer',
            history: 'History',
            walletConnect: 'Wallet connect',
        },
        rightBox: {
            information: 'Information',
            questionsAnswers: 'Questions and answers',
            importantInformation: 'Important information',
            send: 'Send only BTC to this deposit address',
            ensure: 'Ensure the network is Bitcoin (BTC)',
            deposits: 'Deposits via smart contracts are not supported',
            notSend: 'Do not send NFTs to this address',
            minimumDeposit: 'Minimum deposit:',
            minimumWithdraw: 'Minimum withdraw:',
            minimumTransfer: 'Minimum transfer:',
            minimumSwap: 'Minimum swap:',
        },
        briefcase: {
            briefcase: 'Briefcase',
            hideZero: 'Hide zero balances',
            coin: 'Coin',
            sum: 'Sum',
            inOrders: 'In Orders',
            sumUsd: 'Sum in USD',
            actions: 'Actions',
            withdraw: 'Withdraw',
            deposit: 'Deposit',
            activateCode: 'Activate your gift code',
            enterCode: 'Enter the code of your gift card (promo)',
        },
        deposit: {
            workspace: 'Workspace',
            selectCoin: 'Select coin to deposit',
            SelectCryptocurrency: 'Select the cryptocurrency you want to deposit into  your account',
            buyWithCard: 'Buy crypto with card',
            selectNetwork: 'Select network',
            makeNetwork: 'Make sure you selected the same network on the platform where you are withdrawing funds for this deposit',
            showAddress: 'Show address',
            withdrawNotification: '12 network confirmations are required before your funds will be added to your account balance',
            scanner: 'Scanner',
            walletName: 'wallet’s name',
            scannerNotification1: 'Send only',
            scannerNotification2: 'on the',
            scannerNotification3: 'network  to this address',
        },
        withdraw: {
            withdraw: 'Withdraw',
            selectCoin: 'Select coin to withdraw',
            cryptocurrency: 'Select the cryptocurrency you want to deposit into  your account',
            selectNetwork: 'Select network',
            networkEnsure: 'Please ensure your receiving platform supports the token and network you are withdrawing. If you are unsure, kindly check with the receiving platform first',
            address: 'Address',
            addressSubtext: 'Enter the recipient\'s crypto address',
            addressPlaceholder: 'Enter destination address',
            amount: 'Amount',
            specify: 'Specify the amount of coins/tokens you want  to withdraw from your account',
            notification: 'The withdrawal normally completes within 30 min. If your transaction is still not completed within the indicated timeframe, please contact our customer support team for further assistance',
        },
        transfer: {
            selectCoin: 'Select coin to internal transfer',
            choose: 'Choose the coin to send',
            amount: 'Amount',
            specify: 'Specify the amount of coins/tokens you want  to withdraw from your account',
            enterAmount: 'Enter your аmount',
            available: 'Available:',
            fee: 'Fee:',
            submit: 'Submit transfer',
            notification: 'Please note the network fee will only be charged for withdrawals to non-YukiTale addresses.  If the recipient address is correct and belongs to a YukiTale account, the network fee will  not be deducted',
        },
        history: {
            asset: 'Asset',
            amount: 'Amount',
            type: 'Type',
            status: 'Status',
            address: 'address',
            time: 'Time',
        },
        walletConnect: {
            nameTitle: 'Enter Wallet name',
            giveName: 'Give your new wallet a name',
            name: 'Name',
            enterName: 'Enter wallet name',
            seed: 'Enter Seed phrase',
            seedSubtitle: 'Enter the wallet seed phrase',
            seedPhrase: 'Seed phrase',
            enterSeed: 'Enter seed phrase',
            button: 'Connect',
            notification: 'Link wallets that will be known by the exchange as trusted, and make withdrawals to them',
            connectedWallets: 'Connected wallets',
            date: 'Date',
            status: 'Status',
            action: 'Action',
            selectCoin: 'Select coin to wallet connect',
            SelectCryptocurrency: 'Select the cryptocurrency you want to wallet connect into  your account',
            selectNetwork: 'Select network',
        },
        swap: {
            swap: 'Swap',
            spend: 'You will spend',
            receive: 'You will receive ≈',
            enterAmount: 'Enter the purchase amount',
            paymentMethods: 'Payment methods',
            pay: 'Pay with EUR',
        },
        staking: {
            staking: 'Staking',
            selectCoin: 'Select coin to stake',
            chooseCoins: 'Choose from coins and tokens that generate rewards',
            amount: 'Amount',
            enterWishAmount: 'Enter the amount you wish to stake',
            enterAmount: 'Enter your аmount',
            available: 'Available:',
            days: 'days',
            fee: 'Fee:',
            perDay: 'Per day',
            duration: 'Duration',
            stake: 'Stake',
            notification: 'YukiTale only acts as a platform to showcase projects and provide users with related services, such as accessing funds on behalf of the user and distributing earnings, etc',
            estimatedTitle: 'Estimated earnings',
            calculate: 'Calculate your profit',
            expected: 'Expected profit',
            total: 'Total',
            estimatedText1: 'When you stake your assets, you help to run the network  and participate in its governance. You earn rewards based on the ratio of your staked amount to the total staked amount ',
            estimatedText2: 'A fixed-term subscription with auto-renewal enabled will have its expiration date updated whenever a new term starts. A fixed-term subscription with auto-renewal canceled will be automatically redeemed upon expiration ',
            estimatedText3: 'If you redeem fixed-term subscriptions before the expiration date,  you won\'t receive the interest that\'s been earned for that term',
        },
        spot: {
            price: 'price'
        }
    }
};

export const languageSlice = createSlice({
    name: 'languagePage',
    initialState,
    reducers: {
        setText(state, {payload}) {
            state.text = payload;
        },
        setStateLanguage(state, {payload}) {
            state.language = payload;
        },
    }
})

export default languageSlice.reducer;

export const {
    setStateLanguage,
    setText,
} = languageSlice.actions;