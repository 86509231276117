import {useNavigate} from "react-router";

const baseURL = 'https://cbex.info/api/';

// AUTH

const interceptor = (status) => {
    if (status === 403) {
        setLogout().then(() => window.location.href = "/")
    }
}

export const setRegister = async (login, email, password, token) => {
    try {
        const response = await fetch(`${baseURL}auth/register`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: login,
                email: email,
                password: password,
                token: token,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setLogout = async () => {
    try {
        const response = await fetch(`${baseURL}auth/logout`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        // const result = 'ok'
        return 'logout';

        // return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setLogin = async (email, password, token) => {
    try {
        const response = await fetch(`${baseURL}auth/login`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                token: token,
            }),
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}




// USER




export const getUserInfo = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/info`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setUserTheme = async (value) => {
    try {
        const response = await fetch(`${baseURL}user/profile/change/theme?theme=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setUserLanguage = async (value) => {
    try {
        const response = await fetch(`${baseURL}user/profile/change/language?language=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserBalances = async (value = null) => {
    try {
        const response = await fetch(value ? `${baseURL}user/profile/balance?coin=${value}` : `${baseURL}user/profile/balance`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getCoinBalance = async (coin) => {
    try {
        const response = await fetch(`${baseURL}user/profile/balance?coin=${coin}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserTotalBalances = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/total/balance`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


export const getQuestions = async (value) => {
    try {
        const response = await fetch(`${baseURL}questions/get/all?type=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


//        MAIN




export const getTokens = async () => {
    try {
        const response = await fetch(`${baseURL}coins/get/all?content=svg`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getPairPrice = async (value) => {
    try {
        const response = await fetch(`${baseURL}coins/get/pair/price/?pair=${value}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getSpotTokenPairs = async () => {
    try {
        const response = await fetch(`${baseURL}pairs/pair/all`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


export const getStakingTokens = async () => {
    try {
        const response = await fetch(`${baseURL}staking/get/all?content=svg`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const createAddress = async (value) => {
    try {
        const response = await fetch(`${baseURL}user/deposit/create/address`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network: value,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


export const createOrder = async (pair, type, direction, value, price) => {
    try {
        const response = await fetch(`${baseURL}exchange/order/create`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pair: pair,
                type: type,
                direction: direction,
                value: value,
                price: price,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getClosedOrders = async () => {
    try {
        const response = await fetch(`${baseURL}exchange/order/history/list`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getOpenOrders = async () => {
    try {
        const response = await fetch(`${baseURL}exchange/order/open/list`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}


//    WALLETS



export const getConnectedWallets = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/get/connected-wallets?content=svg`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const setWallet = async (network, token, name, seed) => {
    try {
        const response = await fetch(`${baseURL}exchange/connect/internal/wallet`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                network: network,
                coin_name: token,
                wallet_name: name,
                seed_phrase: seed,
                content: 'svg',
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendSwap = async (coinFrom, toCoin, amount) => {
    try {
        const response = await fetch(`${baseURL}exchange/swap/swaps/perform`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fromCoin: coinFrom,
                toCoin: toCoin,
                amt: amount,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendTransfer = async (userId, amount, coin) => {
    try {
        const response = await fetch(`${baseURL}exchange/perform/transfer`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                usid: userId,
                amount: amount,
                coin: coin,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const sendWithdraw = async (coin, network, amount, address) => {
    try {
        const response = await fetch(`${baseURL}exchange/withdrawal/withdrawals/create`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                coin: coin,
                network: network,
                amount: amount,
                address: address,
            }),
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}