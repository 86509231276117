import React from "react";
import styles from './HistoryList.module.scss';
import {useAppSelector} from "../../../../../hooks/redux";
import {getHistory, getText} from "../../../../../store/selectors";


const HistoryList = () => {
    const {history} = useAppSelector(getText);
    const list = useAppSelector(getHistory);
    return (
        <div className={styles.historyList}>
            <div className={styles.historyList__labels}>
                <p>{history.asset}</p>
                <p>{history.amount}</p>
                <p>{history.type}</p>
                <p>{history.status}</p>
                <p>{history.address}</p>
                <p>{history.time}</p>
            </div>
            {list && list.length > 0 && (
                list.map((item, index) => (
                    <div key={index} className={styles.historyList__items}>
                        <div className={styles.historyList__token}>
                            <img src={item.icon} alt=""/>
                            <p>{item.name}</p>
                            <span>{`(${item.network})`}</span>
                        </div>
                        <p>{item.amount}</p>
                        <p>{item.type}</p>
                        <p>{item.status}</p>
                        <p>{item.address}</p>
                        <p>{item.time}</p>
                    </div>
                ))
            )}
        </div>
    )
}

export default HistoryList;