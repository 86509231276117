import React from "react";

export const getLanguages = async (value) => {
    const english = {
        landing: {
            startText: 'AFS: Secure, Fast,  and Reliable Crypto Trading',
            getStarted: 'Get Started',
            followTitle: 'Follow our news and offers',
            view: 'VIEW',
            tradingTitle: 'World class trading platform',
            tradingSubTitle: 'We rank top among first tier exchanges with highest number of listed crypto',
            customersTitle: 'Why customers choose us?',
            customersSubTitle: 'Get all the benefits of YukiTale’s crypto exchange  in the palm of your hand. Here are a few reasons why you should choose us',
            box1title: 'Secure and Trusted',
            box2title: 'Fast and Efficient',
            box3title: 'Powerful Trading Tools',
            box4title: 'Wide Range of Crypto',
            box1text: 'Your security is our top priority. We employ industry-leading security measures to safeguard your funds and personal information. You can trade with confidence knowing that your assets are protected',
            box2text: 'Our advanced trading engine ensures lightning-fast order execution, enabling you to take advantage of market opportunities as they arise. No more waiting for sluggish transactions – experience the speed and efficiency of our platform',
            box3text: 'From real-time price charts and technical analysis indicators to advanced order types and customizable trading strategies, we provide the tools you need to make informed trading decisions',
            box4text: 'Discover a diverse selection of cryptocurrencies on our platform. Whether you\'re interested in Bitcoin, Ethereum, or other popular altcoins, we\'ve got you covered. Expand your portfolio and explore new investment opportunities',
            exchangeTop: 'App',
            exchangeTitle: 'Trade anywhere and anytime with AFS',
            exchangeDescription: 'Enjoy the convenience of AFS crypto trading platform. Make money on cryptocurrency anytime, anywhere as AFS',
            exchangeBtnComputer: 'Download computer',
            exchangeBtnMobile: 'Download mobile',
            newsletterTitle: 'Subscribe to our newsletter',
            newsletterText: 'We can’t promise a new email every Monday, but we can promise we won’t spam you until we have  a great announcement to share',
            newsletterEmail: 'Enter your email',
            newsletterSendBtn: 'Subscribe',
            lockText: 'Unlock everything Trove Pro has to offer',
            signIn: 'Sign in',
            signUp: 'Sign up',
        },
        signIn: {
            title: 'Sign in',
            subTitle: 'Welcome back! Please enter your details',
            email: 'Email',
            emailPlaceholder: 'Enter your email',
            password: 'Password',
            passwordPlaceholder: 'Enter your password',
            notHaveAccount: 'Don’t have an account?',
            signUpLink: 'Sign up for free',
            captchaError: 'Please confirm that you are not a robot',
        },
        signUp: {
            title: 'Create new account',
            subTitle: 'Welcome! Please enter your details',
            login: 'Login',
            loginPlaceholder: 'Enter your login',
            email: 'Email',
            emailPlaceholder: 'Enter your email',
            password: 'Password',
            passwordPlaceholder: 'Enter your password',
            passwordRepeat: 'Repeat the password',
            signUp: 'Sign up',
            captchaError: 'Please confirm that you are not a robot',
        },
        header: {
            login: 'Log in',
            signUp: 'Sign up',
            trading: 'Trading',
            p2p: 'P2P',
            staking: 'Staking',
            wallet: 'Wallet',
            swap: 'Swap',
            lastActivity: 'Last activity time:',
            totalBalance: 'Total balance',
            spotTrading: 'Spot trading',
            earn: 'Earn',
            support: 'Support',
            buyCrypto: 'Buy crypto',
            tools: 'Tools',
        },
        menu: {
            adminPanel: 'Admin Panel',
            wallet: 'Wallet',
            notifications: 'Notifications',
            accountSettings: 'Account settings',
            verification: 'Verification',
            giftCodes: 'Gift codes',
            support: 'Support',
            lightTheme: 'Light theme',
            exit: 'Exit',
        },
        headerNavigation: {
            briefcase: 'Briefcase',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            transfer: 'Transfer',
            history: 'History',
            walletConnect: 'Wallet connect',
        },
        rightBox: {
            information: 'Information',
            questionsAnswers: 'Questions and answers',
            importantInformation: 'Important information',
            send: 'Send only BTC to this deposit address',
            ensure: 'Ensure the network is Bitcoin (BTC)',
            deposits: 'Deposits via smart contracts are not supported',
            notSend: 'Do not send NFTs to this address',
            minimumDeposit: 'Minimum deposit:',
            minimumWithdraw: 'Minimum withdraw:',
            minimumTransfer: 'Minimum transfer:',
            minimumSwap: 'Minimum swap:',
        },
        briefcase: {
            briefcase: 'Briefcase',
            hideZero: 'Hide zero balances',
            coin: 'Coin',
            sum: 'Sum',
            inOrders: 'In Orders',
            sumUsd: 'Sum in USD',
            actions: 'Actions',
            withdraw: 'Withdraw',
            deposit: 'Deposit',
            activateCode: 'Activate your gift code',
            enterCode: 'Enter the code of your gift card (promo)',
        },
        deposit: {
            workspace: 'Workspace',
            selectCoin: 'Select coin to deposit',
            SelectCryptocurrency: 'Select the cryptocurrency you want to deposit into  your account',
            buyWithCard: 'Buy crypto with card',
            selectNetwork: 'Select network',
            makeNetwork: 'Make sure you selected the same network on the platform where you are withdrawing funds for this deposit',
            showAddress: 'Show address',
            withdrawNotification: '12 network confirmations are required before your funds will be added to your account balance',
            scanner: 'Scanner',
            walletName: 'wallet’s name',
            scannerNotification1: 'Send only',
            scannerNotification2: 'on the',
            scannerNotification3: 'network  to this address',
        },
        withdraw: {
            withdraw: 'Withdraw',
            selectCoin: 'Select coin to withdraw',
            cryptocurrency: 'Select the cryptocurrency you want to deposit into  your account',
            selectNetwork: 'Select network',
            networkEnsure: 'Please ensure your receiving platform supports the token and network you are withdrawing. If you are unsure, kindly check with the receiving platform first',
            address: 'Address',
            addressSubtext: 'Enter the recipient\'s crypto address',
            addressPlaceholder: 'Enter destination address',
            amount: 'Amount',
            specify: 'Specify the amount of coins/tokens you want  to withdraw from your account',
            notification: 'The withdrawal normally completes within 30 min. If your transaction is still not completed within the indicated timeframe, please contact our customer support team for further assistance',
        },
        transfer: {
            selectCoin: 'Select coin to internal transfer',
            choose: 'Choose the coin to send',
            amount: 'Amount',
            specify: 'Specify the amount of coins/tokens you want  to withdraw from your account',
            enterAmount: 'Enter your аmount',
            available: 'Available:',
            fee: 'Fee:',
            submit: 'Submit transfer',
            notification: 'Please note the network fee will only be charged for withdrawals to non-YukiTale addresses.  If the recipient address is correct and belongs to a YukiTale account, the network fee will  not be deducted',
        },
        history: {
            asset: 'Asset',
            amount: 'Amount',
            type: 'Type',
            status: 'Status',
            address: 'address',
            time: 'Time',
        },
        walletConnect: {
            nameTitle: 'Enter Wallet name',
            giveName: 'Give your new wallet a name',
            name: 'Name',
            enterName: 'Enter wallet name',
            seed: 'Enter Seed phrase',
            seedSubtitle: 'Enter the wallet seed phrase',
            seedPhrase: 'Seed phrase',
            enterSeed: 'Enter seed phrase',
            button: 'Connect',
            notification: 'Link wallets that will be known by the exchange as trusted, and make withdrawals to them',
            connectedWallets: 'Connected wallets',
            date: 'Date',
            status: 'Status',
            action: 'Action',
            selectCoin: 'Select coin to wallet connect',
            SelectCryptocurrency: 'Select the cryptocurrency you want to wallet connect into  your account',
            selectNetwork: 'Select network',
        },
        swap: {
            swap: 'Swap',
            spend: 'You will spend',
            receive: 'You will receive ≈',
            enterAmount: 'Enter the purchase amount',
            paymentMethods: 'Payment methods',
            pay: 'Pay with EUR',
        },
        staking: {
            staking: 'Staking',
            selectCoin: 'Select coin to stake',
            chooseCoins: 'Choose from coins and tokens that generate rewards',
            amount: 'Amount',
            enterWishAmount: 'Enter the amount you wish to stake',
            enterAmount: 'Enter your аmount',
            available: 'Available:',
            days: 'days',
            fee: 'Fee:',
            perDay: 'Per day',
            duration: 'Duration',
            stake: 'Stake',
            notification: 'YukiTale only acts as a platform to showcase projects and provide users with related services, such as accessing funds on behalf of the user and distributing earnings, etc',
            estimatedTitle: 'Estimated earnings',
            calculate: 'Calculate your profit',
            expected: 'Expected profit',
            total: 'Total',
            estimatedText1: 'When you stake your assets, you help to run the network  and participate in its governance. You earn rewards based on the ratio of your staked amount to the total staked amount ',
            estimatedText2: 'A fixed-term subscription with auto-renewal enabled will have its expiration date updated whenever a new term starts. A fixed-term subscription with auto-renewal canceled will be automatically redeemed upon expiration ',
            estimatedText3: 'If you redeem fixed-term subscriptions before the expiration date,  you won\'t receive the interest that\'s been earned for that term',
        },
        spot: {
            price: 'price'
        }
    };

    const russian = {
        landing: {
            startText: 'AFS: безопасная, быстрая и надежная торговля криптовалютой',
            getStarted: 'Начать',
            followTitle: 'Следите за нашими новостями и предложениями',
            view: 'ПРОСМОТР',
            tradingTitle: 'Торговая платформа мирового класса',
            tradingSubTitle: 'Мы занимаем первое место среди бирж первого уровня с наибольшим количеством зарегистрированных криптовалют.',
            customersTitle: 'Почему клиенты выбирают нас?',
            customersSubTitle: 'Получите все преимущества криптовалютной биржи YukiTale у вас на ладони. Вот несколько причин, почему вам следует выбрать нас',
            box1title: 'Безопасный и надежный',
            box2title: 'Быстро и эффективно',
            box3title: 'Мощные торговые инструменты',
            box4title: 'Широкий спектр криптовалют',
            box1text: 'Ваша безопасность – наш главный приоритет. Мы применяем ведущие в отрасли меры безопасности для защиты ваших средств и личной информации. Вы можете торговать с уверенностью, зная, что ваши активы защищены',
            box2text: 'Наш продвинутый торговый механизм обеспечивает молниеносное исполнение ордеров, позволяя вам воспользоваться рыночными возможностями по мере их возникновения. Больше не нужно ждать вялых транзакций — оцените скорость и эффективность нашей платформы',
            box3text: 'От графиков цен в реальном времени и индикаторов технического анализа до расширенных типов ордеров и настраиваемых торговых стратегий — мы предоставляем инструменты, необходимые для принятия обоснованных торговых решений',
            box4text: 'Откройте для себя разнообразный выбор криптовалют на нашей платформе. Если вас интересуют Биткойн, Эфириум или другие популярные альткойны, мы предоставим вам всю необходимую информацию. Расширьте свой портфель и изучите новые инвестиционные возможности',
            exchangeTop: 'Приложение',
            exchangeTitle: 'Торгуйте где угодно и когда угодно с AFS',
            exchangeDescription: 'Наслаждайтесь удобством криптоторговой платформы AFS. Зарабатывайте деньги на криптовалюте в любое время и в любом месте с помощью AFS',
            exchangeBtnComputer: 'Скачать на компьютер',
            exchangeBtnMobile: 'Скачать на мобильный',
            newsletterTitle: 'Подписывайтесь на нашу новостную рассылку',
            newsletterText: 'Мы не можем обещать, что будем получать новое электронное письмо каждый понедельник, но можем пообещать, что не будем рассылать вам спам, пока не подготовим отличное объявление.',
            newsletterEmail: 'Введите адрес электронной почты',
            newsletterSendBtn: 'Подписаться',
            lockText: 'Откройте для себя все, что может предложить Trove Pro',
            signIn: 'Войти',
            signUp: 'Зарегистрироваться',
        },
        signIn: {
            title: 'Авторизация',
            subTitle: 'Добро пожаловать! Пожалуйста, введите свои данные',
            email: 'Электронная почта',
            emailPlaceholder: 'Введите адрес электронной почты',
            password: 'Пароль',
            passwordPlaceholder: 'Введите ваш пароль',
            notHaveAccount: 'У вас нет учетной записи?',
            signUpLink: 'Бесплатно зарегестрироваться',
            captchaError: 'Подтвердите что вы не робот',
        },
        signUp: {
            title: 'Создать новый аккаунт',
            subTitle: 'Добро пожаловать! Пожалуйста, введите свои данные',
            login: 'Логин',
            loginPlaceholder: 'Введите ваш логин',
            email: 'Электронная почта',
            emailPlaceholder: 'Введите адрес электронной почты',
            password: 'Пароль',
            passwordPlaceholder: 'Введите ваш пароль',
            passwordRepeat: 'Повторите пароль',
            signUp: 'Зарегистрироваться',
            captchaError: 'Подтвердите что вы не робот',
        },
        header: {
            login: 'Авторизация',
            signUp: 'Регистрация',
            trading: 'Торговля',
            p2p: 'P2P',
            staking: 'Стейкинг',
            wallet: 'Кошелек',
            swap: 'Обмен',
            lastActivity: 'Время последней активности:',
            totalBalance: 'Общий баланс',
            spotTrading: 'Спотовая торговля',
            earn: 'Зарабатывать',
            support: 'Поддержка',
            buyCrypto: 'Купить криптовалюту',
            tools: 'Инструменты',
        },
        menu: {
            adminPanel: 'Панель администратора',
            wallet: 'Кошелек',
            notifications: 'Уведомления',
            accountSettings: 'Настройки учетной записи',
            verification: 'Проверка',
            giftCodes: 'Подарочные коды',
            support: 'Поддержка',
            lightTheme: 'Светлая тема',
            exit: 'Выход',
        },
        headerNavigation: {
            briefcase: 'Портфель',
            deposit: 'Депозит',
            withdraw: 'Вывод',
            transfer: 'Передача',
            history: 'История',
            walletConnect: 'Подключить кошелек',
        },
        rightBox: {
            information: 'Информация',
            questionsAnswers: 'Вопросы и ответы',
            importantInformation: 'Важная информация',
            send: 'Отправляйте только BTC на этот адрес депозита',
            ensure: 'Убедитесь, что сеть — это биткойн (BTC)',
            deposits: 'Депозиты через смарт-контракты не поддерживаются.',
            notSend: 'Не отправляйте NFTs на этот адрес',
            minimum: 'Минимальный депозит:',
            minimumWithdraw: 'Минимальная сумма вывода:',
            minimumTransfer: 'Минимальная сумма перевода:',
            minimumSwap: 'Минимальный своп:',
        },
        briefcase: {
            briefcase: 'Портфель',
            hideZero: 'Скрыть нулевые балансы',
            coin: 'Монета',
            sum: 'Всего',
            inOrders: 'В заказах',
            sumUsd: 'Всего в долларах',
            actions: 'Действия',
            withdraw: 'Вывод',
            deposit: 'Депозит',
            activateCode: 'Активируйте свой подарочный код',
            enterCode: 'Введите код вашей подарочной карты (промо)',
        },
        deposit: {
            workspace: 'Рабочее пространство',
            selectCoin: 'Выберите монету для внесения',
            SelectCryptocurrency: 'Выберите криптовалюту, которую вы хотите внести на свой счет.',
            buyWithCard: 'Купить криптовалюту с помощью карты',
            selectNetwork: 'Выберите сеть',
            makeNetwork: 'Убедитесь, что вы выбрали ту же сеть на платформе, где вы выводите средства для этого депозита.',
            showAddress: 'Показать адрес',
            withdrawNotification: 'Прежде чем ваши средства будут добавлены на баланс вашего счета, необходимо 12 сетевых подтверждений.',
            scanner: 'Сканер',
            walletName: 'имя кошелька',
            scannerNotification1: 'Отправляйте на этот адрес только',
            scannerNotification2: 'в сети',
            scannerNotification3: '',
        },
        withdraw: {
            withdraw: 'Вывод',
            selectCoin: 'Выберите монету для вывода',
            cryptocurrency: 'Выберите криптовалюту, которую вы хотите внести на свой счет.',
            selectNetwork: 'Выберите сеть',
            networkEnsure: 'Пожалуйста, убедитесь, что ваша принимающая платформа поддерживает токен и сеть, которые вы выводите. Если вы не уверены, пожалуйста, сначала проверьте с принимающей платформой',
            address: 'Адрес',
            addressSubtext: 'Введите криптоадрес получателя',
            addressPlaceholder: 'Введите адрес назначения',
            amount: 'Количество',
            specify: 'Укажите сумму монет/токенов, которую вы хотите вывести со своего счета.',
            notification: 'Вывод обычно завершается в течение 30 мин. Если ваша транзакция все еще не завершена в течение указанного периода времени, свяжитесь с нашей службой поддержки клиентов для получения дальнейшей помощи.',
        },
        transfer: {
            selectCoin: 'Выберите монету для внутреннего перевода',
            choose: 'Выберите монету для отправки',
            amount: 'Количество',
            specify: 'Укажите сумму монет/токенов, которую вы хотите вывести со своего счета.',
            enterAmount: 'Введите сумму',
            available: 'Доступный:',
            fee: 'Платеж:',
            submit: 'Отправить перевод',
            notification: 'Обратите внимание, что сетевая комиссия будет взиматься только за вывод средств на адреса, не относящиеся к YukiTale. Если адрес получателя правильный и принадлежит учетной записи YukiTale, сетевая комиссия не будет вычтена',
        },
        history: {
            asset: 'Объект',
            amount: 'Количество',
            type: 'Тип',
            status: 'Статус',
            address: 'Адрес',
            time: 'Время',
        },
        walletConnect: {
            nameTitle: 'Введите имя кошелька',
            giveName: 'Дайте имя вашему новому кошельку',
            name: 'Имя',
            enterName: 'Введите имя кошелька',
            seed: 'Введите начальную фразу',
            seedSubtitle: 'Введите начальную фразу кошелька',
            seedPhrase: 'Исходная фраза',
            enterSeed: 'Введите начальную фразу',
            button: 'Соединить',
            notification: 'Свяжите кошельки, которые будут известны бирже как доверенные, и выводите на них средства.',
            connectedWallets: 'Подключенные кошельки',
            date: 'Дата',
            status: 'Статус',
            action: 'Действие',
            selectCoin: 'Выберите монету для подключения кошелька',
            SelectCryptocurrency: 'Выберите криптовалюту, кошелек которой вы хотите подключить к своей учетной записи.',
            selectNetwork: 'Выберите сеть',
        },
        swap: {
            swap: 'Обмен',
            spend: 'Вы потратите',
            receive: 'Вы получите ≈',
            enterAmount: 'Введите сумму покупки',
            paymentMethods: 'Способы оплаты',
            pay: 'Оплатить в евро',
        },
        staking: {
            staking: 'Ставка',
            selectCoin: 'Выберите монету для ставки',
            chooseCoins: 'Выбирайте монеты и жетоны, которые генерируют вознаграждения',
            amount: 'Количество',
            enterWishAmount: 'Введите сумму, которую вы хотите поставить',
            enterAmount: 'Введите сумму',
            available: 'Доступно:',
            days: 'дней',
            fee: 'Платеж:',
            perDay: 'В день',
            duration: 'Продолжительность',
            stake: 'Ставка',
            notification: 'YukiTale выступает только в качестве платформы для демонстрации проектов и предоставления пользователям сопутствующих услуг, таких как доступ к средствам от имени пользователя и распределение доходов и т. д.',
            estimatedTitle: 'Предполагаемый доход',
            calculate: 'Рассчитайте свою прибыль',
            expected: 'Ожидаемая прибыль',
            total: 'Общий',
            estimatedText1: 'Когда вы делаете ставку на свои активы, вы помогаете управлять сетью и участвуете в ее управлении. Вы получаете вознаграждение, основанное на соотношении вашей поставленной суммы к общей поставленной сумме.',
            estimatedText2: ' Подписка с фиксированным сроком действия с включенным автоматическим продлением будет обновляться каждый раз при начале нового срока. Подписка с фиксированным сроком действия с отмененным автоматическим продлением будет автоматически погашена по истечении срока действия.',
            estimatedText3: 'Если вы воспользуетесь срочными подписками до даты истечения срока, вы не получите проценты, заработанные за этот срок.',
        },
        spot: {
            price: 'цена'
        }
    };

    switch (value) {
        case 'english' : return english;
        case 'russian' : return russian;
    }
}