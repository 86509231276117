import React, {useEffect} from "react";
import styles from "./Home.module.scss";
import MainHeader from "./MainHeader/MainHeader";
import {Route, Routes} from "react-router-dom";
import Settings from "./Settings/Settings";
import Main from "./Main/Main";
import LandingFooter from "../LandingPage/LandingFooter/LandingFooter";
import Swap from "./Main/Swap/Swap";
import Staking from "./Main/Staking/Staking";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getIsDarkTheme, getProfileInfo} from "../../store/selectors";
import FooterNavigation from "./FooterNavigation/FooterNavigation";
import Spot from "./Main/Spot/Spot";
import NotificationsPage from "./Main/NotificationsPage/NotificationsPage";
import {getTokens, getUserBalances, getUserTotalBalances} from "../../api/api";
import {setTokens} from "../../store/main-slice";
import Event from "../Common/Event/Event";
import {setBalance, setEvent} from "../../store/account-slice";
import {updateBalanceList} from "../../store/wallet-slice";

let eventSocket = null;

const Home = () => {
    const isDark = useAppSelector(getIsDarkTheme);
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(getProfileInfo);
    let save = 0;

    useEffect(() => {
        getTokens().then((result) => {
            if (result && result.length > 0) {
                dispatch(setTokens(result));
            }
        });
    }, [])

    useEffect(() => {
        if (userInfo && userInfo.id && eventSocket === null) {
            eventSocket = new WebSocket(`wss://cbex.info/ws/notifications`);
            eventSocket.onopen = () => {
                eventSocket.send(`${userInfo.id}`);
            }

        }
    }, [userInfo]);

    useEffect(() => {
        return () => {
            if (save === 0) {
                save = 1;
            } else {
                eventSocket.close()
                eventSocket = null;
                save = 0;
            }
        }
    }, [])

    // if (eventSocket) {
    //     eventSocket.onmessage = (e) => {
    //         const message = JSON.parse(e.data)
    //         console.log(message);
    //         if (message && message.message) {
    //             dispatch(setEvent(message.message))
    //         }
    //         if (message && message.type === 'TRANSACTION' && message.content && message.content.coin) {
    //             console.log('START')
    //             getUserBalances(message.content.coin).then((response) => {
    //                 console.log(response)
    //                 if (response && response.coin) {
    //                     dispatch(updateBalanceList(response));
    //                 }
    //             })
    //         }
    //     }
    // }

    useEffect(() => {
        if (eventSocket !== null) {
            eventSocket.onmessage = (e) => {
                const message = JSON.parse(e.data)
                if (message && message.message) {
                    dispatch(setEvent(message.message))
                }
                if (message && message.type === 'TRANSACTION' && message.content && message.content.coin) {
                    getUserBalances(message.content.coin).then((response) => {
                        if (response && response.coin) {
                            dispatch(updateBalanceList(response));
                        }
                    });
                    getUserTotalBalances().then((response) => {
                        if (response && response[0]) {
                            dispatch(setBalance(response[0]));
                        }
                    })
                }
            }
        }
    }, [eventSocket])


    return (
        <div className={isDark ? `${styles.wrapper} dark` : `${styles.wrapper} light`}>
            <div className={styles.home}>
                <MainHeader/>
                <div className='mainContent'>
                    <Routes>
                        <Route path={'/*'} element={<Main/>}/>
                        <Route path={'/swap'} element={<Swap/>}/>
                        <Route path={'/staking'} element={<Staking/>}/>
                        <Route path={'/spot'} element={<Spot/>}/>
                        <Route path={'/notifications'} element={<NotificationsPage/>}/>
                        <Route path={'settings/*'} element={<Settings/>}/>
                    </Routes>
                </div>
                {window.innerWidth >= 1024 && (
                    <LandingFooter decorHide/>
                )}
                {window.innerWidth < 1024 && (
                    <FooterNavigation/>
                )}
                <Event/>
            </div>
        </div>
    )
}

export default Home;