import React from "react";
import preloaderWhite from '../../../assets/icons/preloader.svg';
import preloaderBlue from '../../../assets/icons/preloaderBlue.svg';
import styles from './Preloader.module.scss';

export const Preloader = () => {
    return (
        <div className='preloaderWrapper'>
            <span className={styles.preloader}/>
        </div>
    )
}