import React, {useEffect, useState} from "react";
import styles from './QuantityTotalBox.module.scss';


const QuantityTotalBox = ({quantity, setQuantity, total, setTotal, quantityToken, totalToken, priceChoose, operation, coursePrice, balance, isError, setIsError, setProcess}) => {
    useEffect(() => {
        quantity > balance ? setIsError(true) : setIsError(false)
    }, [quantity])

    const onChangeQuantity = (value) => {
        setQuantity(value);

        setTotal(operation === 'Buy'
            ? Number(value / coursePrice).toFixed(6)
            : Number(value * coursePrice).toFixed(2)
        )

        // setProcess()
        if (Number(value) > 0) {
            let calcProcess = (value / Number(balance)) * 100;
            setProcess(calcProcess)
        } else if (value === '') {
            setProcess(0)
        }

    }

    const onTotalQuantity = (value) => {
        quantity > balance ? setIsError(true) : setIsError(false)
        setTotal(value);

        setQuantity(operation === 'Buy'
            ? Number(value * coursePrice).toFixed(2)
            : Number(value / coursePrice).toFixed(6)
        )

        if (Number(quantity) > 0) {
            let calcProcess = operation === 'Buy' ? (Number(value * coursePrice).toFixed(2) / Number(balance)) * 100 : (Number(value / coursePrice).toFixed(6) / Number(balance)) * 100
            setProcess(calcProcess)
        } else if (quantity === '') {
            setProcess(0)
        }

    }

    return (
        <div className={styles.quantityTotalBox}>
            <div className={isError ? `${styles.priceInputBox} ${styles.error}` : styles.priceInputBox} >
                <label htmlFor='quantityField'><p>Quantity</p></label>
                <div className={styles.priceInputBox__field}>
                    <input name='quantityField' type="number" value={quantity}
                           onChange={(e) => onChangeQuantity(e.target.value)} placeholder={`0.00`}/>
                    <span>{quantityToken}</span>
                </div>
            </div>
            {priceChoose === 'Limit' && (
                <div className={styles.priceInputBox}>
                    <label htmlFor='totalField'><p>Total</p></label>
                    <div className={styles.priceInputBox__field}>
                        <input name='totalField' type="number" value={total}
                               onChange={(e) => onTotalQuantity(e.target.value)} placeholder={`0.00`}/>
                        <span>{totalToken}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default QuantityTotalBox;