import React, {useEffect, useState} from "react";
import styles from './SwapLeftBox.module.scss';
import FieldSelect from "../../../../Common/FieldSelect/FieldSelect";
import {Preloader} from "../../../../Common/Preloader/Preloader";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {getIsDarkTheme, getSaveSwap} from "../../../../../store/selectors";
import {setEvent, setSaveSwap} from "../../../../../store/account-slice";
import {sendSwap} from "../../../../../api/api";

let priceSocket = null;

const SwapLeftBox = ({swap, tokenList, tokenFrom, setTokenFrom}) => {
    const isDark = useAppSelector(getIsDarkTheme);
    const dispatch = useAppDispatch();
    let save = 0;
    const secondSave = useAppSelector(getSaveSwap)
    const [isLoading, setIsLoading] = useState(false);

    const [listFrom, setListFrom] = useState(null);
    const [listTo, setListTo] = useState(null);

    const [tokenTo, setTokenTo] = useState(tokenList[5]);

    const [amountFrom, setAmountFrom] = useState('');
    const [amountTo, setAmountTo] = useState('');

    const [price, setPrice] = useState(null);


    useEffect(() => {
        setListTo(tokenList.filter(item => item.label !== tokenFrom.label));
        setAmountFrom('');
    }, [tokenFrom])

    useEffect(() => {
        setListFrom(tokenList.filter(item => item.label !== tokenTo.label));
    }, [tokenTo])

    useEffect(() => {
        return () => {
            if (save === 0) {
                save = 1;
            } else {
                priceSocket.close()
                dispatch(setSaveSwap(0));
                priceSocket = null;
                save = 0;
            }
        }
    }, [])

    useEffect(() => {
        if (priceSocket === null) {
            priceSocket = new WebSocket('wss://cbex.info/ws/pairs');
            priceSocket.onmessage = function (e) {
                if (price !== JSON.parse(e.data).price) {
                    setPrice(JSON.parse(e.data).price);
                }
            }
        }
    }, [])


    useEffect(() => {
        if (tokenFrom && tokenTo && secondSave === 0) {
            setPrice(null)
            priceSocket.onopen = () => {
                priceSocket.send(`${tokenFrom.label}${tokenTo.label}`);
            }
            setTimeout(() => {
                dispatch(setSaveSwap(1));
            }, 1500)
        } else if (tokenFrom && tokenTo && secondSave === 1) {
            setPrice(null)
            if (tokenFrom.label === 'USDT') {
                priceSocket.send(`${tokenTo.label}${tokenFrom.label}`);
            } else {
                priceSocket.send(`${tokenFrom.label}${tokenTo.label}`);
            }
            priceSocket.onmessage = function (e) {
                if (price !== JSON.parse(e.data).price) {
                    setPrice(JSON.parse(e.data).price);
                }
            }
        }
    }, [tokenFrom, tokenTo])

    const onSwap = () => {
        setIsLoading(true);
        if (tokenFrom && tokenTo && amountFrom > 0) {
            sendSwap(tokenFrom.label, tokenTo.label, amountFrom).then((res) => {
                if (res && res.status) {
                    dispatch(setEvent(`${res.status} ${res.received}`));
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
        }

    }

    return (
        <div className={styles.swapLeftBox}>
            <div className={styles.swapFields}>
                {!price && (
                    <div className='loadingLocal' style={!isDark ? {backgroundColor: 'rgba(0, 0, 0, 0.05)'} : null}>
                        <Preloader/>
                    </div>
                )}
                {listFrom && tokenFrom && (
                    <FieldSelect label={swap.spend} placeholder={`${tokenFrom.settings.minSwapAmount}-36,700`} selectList={listFrom} value={tokenFrom}
                                 setValue={setTokenFrom} amount={amountFrom} setAmount={setAmountFrom} from
                                 setVersusField={setAmountTo} price={price} fromLabel={tokenFrom.label}
                                 amountFrom={amountFrom} />
                )}
                {listTo && tokenFrom && (
                    <FieldSelect label={swap.receive} placeholder={swap.enterAmount}
                                 selectList={listTo} value={tokenTo} setValue={setTokenTo} amount={amountTo}
                                 setAmount={setAmountTo} setVersusField={setAmountFrom} price={price}
                                 fromLabel={tokenFrom.label} amountFrom={amountFrom}/>
                )}
            </div>
            {price && tokenFrom.label !== 'USDT' && (
                <p className={styles.value}>1 {tokenFrom.label} ≈ {(1 * price)} {tokenTo.label}</p>
            )}
            {price && tokenFrom.label === 'USDT' && (
                <p className={styles.value}>1 {tokenTo.label} ≈ {(1 * price)} {tokenFrom.label}</p>
            )}
            {/*<h4>{swap.paymentMethods}</h4>*/}
            {/*<div>*/}
            {/*    <IconSelect selectList={payments} value={payment} setValue={setPayment} />*/}
            {/*</div>*/}
            <div className={styles.actions}>
                <button className={'blueBtn'} type='button' onClick={() => onSwap()}
                        disabled={amountFrom === '' || Number(amountFrom) === 0}>
                    <span>{swap.pay}</span>
                </button>
            </div>
            {isLoading && (
                <div className='loadingLocal' style={{position: 'fixed'}}>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default SwapLeftBox;